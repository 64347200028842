<div *ngIf="downloadEnabled && data?.length" ngbDropdown placement="bottom-right" class="d-flex more-actions">
    <button class="action-icon sm" ngbDropdownToggle>
        <far i="ellipsis-v"></far>
    </button>
    <div ngbDropdownMenu>
        <button (click)="onDownload('XLSX')" class="dropdown-item">
            <fal i="download" class="icon"></fal>
            Download As .XLSX
        </button>
        <button (click)="onDownload('CSV')" class="dropdown-item">
            <fal i="download" class="icon"></fal>
            Download As .CSV
        </button>
        <button (click)="onPrint()" class="dropdown-item">
            <fal i="print" class="icon"></fal>
            Print
        </button>
    </div>
</div>
<app-list-preview
    #tableElement
    *ngIf="data"
    [data]="data"
    [limit]="limit"
    [maxSize]="7"
    [hasLimit]="hasLimit"
    [detailView]="showDetail"
    [shouldShowMoreButton]="showMoreButton"
    [enforceMoreButton]="enforceMoreButton"
    [shouldShowPaginationComponents]="shouldShowPaginationComponents"
    [tableHeadTemplate]="tHead"
    [tableRowTemplate]="tRow"
    [title]="title || definition.title"
    [emptyText]="emptyText"
    [hasScrollContainer]="!stickyHeader && hasScrollContainer"
    tableClasses="{{definition.rowAction || reviewType ? 'table-hover' : ''}} {{tableClasses}}"
    [smallTable]="smallTable"
    (paginationChange)="paginationChange.emit()"
    [tableStartTemplate]="tableStartTemplate"
    [tableEndTemplate]="tableEndTemplate">
</app-list-preview>
<ng-template #tHead let-shouldShowDetails="showDetail">
    <tr smart-table-header
        [class.sticky-row]="stickyHeader"
        [definition]="definition"
        [data]="dataSorted"
        [reviewType]="reviewType"
        [selectable]="selectable"
        [hasSorting]="hasSorting"
        [sorting]="sorting"
        [insertTemplateCols]="insertTemplateCols"
        [shouldShowDetails]="shouldShowDetails"
        (selectAll)="selectAll($event)"
        (sortSelect)="onSortSelect($event)">
    </tr>
</ng-template>
<ng-template #tRow let-item="row" let-items="rows" let-last="last" let-shouldShowDetails="showDetail">
    <tr smart-table-row
        [ngbTooltip]="getTooltip(item)"
        [definition]="definition"
        [reviewType]="reviewType"
        [item]="item"
        [tableData]="items"
        [selectable]="selectable"
        [colTemplates]="colTemplates"
        [insertTemplateCols]="insertTemplateCols"
        [shouldShowDetails]="shouldShowDetails"
        (selectRow)="selectRow($event)"
        (openSlidePanel)="onOpenSlidePanel($event)"
        [last]="last"
        [sorting]="sorting"
        [attr.data-test-id]="definition?.title">
    </tr>
    <ng-container *ngIf="extraRowTemplate">
        <ng-container *ngTemplateOutlet="extraRowTemplate; context: {item: item, items: items, last: last, shouldShowDetails: shouldShowDetails}"></ng-container>
    </ng-container>
</ng-template>
