import {ElementRef, Injectable} from '@angular/core';

export interface PrintCustomHeader {
    text: string;
    tag: string;
    classes?: string;
    style?: string;
}
@Injectable({
    providedIn: 'root',
})
export class PrintService {
    print(url?: string, element?: string) {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        if (url) {
            iframe.src = url;
        } else {
            const blob = new Blob([element], {type: 'text/html'});
            iframe.src = URL.createObjectURL(blob);
        }
        document.body.appendChild(iframe);
        iframe.onload = () => {
            const contentWindow = iframe.contentWindow;

            if (contentWindow) {
                const onPrintEndRefocus = () => {
                    document.body.removeChild(iframe);
                    if (url) URL.revokeObjectURL(url);
                    window.removeEventListener('focus', onPrintEndRefocus);
                };
                window.addEventListener('focus', onPrintEndRefocus);
                contentWindow.print();
            }
        };
    }

    printElement(elem: ElementRef, title?: string) {
        this.print(null, PrintService.getPrintHtmlStructure(elem.nativeElement.innerHTML, title));
    }

    printTable(data: (string | number)[][], title?: string, customStyles?: string, customHeaders?: PrintCustomHeader[]) {
        const headers = `<thead><tr>${data[0].map(element => `<th>${element}</th>`).join('')}</tr></thead>`;
        const dataRows = `<tbody>${data.slice(1).map(dataRow => {
            const columns = dataRow.map(dataCol => `<td>${dataCol}</td>`).join('');
            return `<tr>${columns}</tr>`;
        }).join('')}</tbody>`;
        let tableBody = `
            <table class="table">
                ${[headers, dataRows].join('')}
            </table>
        `;
        if (customHeaders) {
            let customHeaderBody = '';
            customHeaders.forEach(h => {
                if (h.text && h.text.length) {
                    customHeaderBody = `${customHeaderBody}<${h.tag} class="${h.classes}" style="${h.style}">${h.text}</${h.tag}>`;
                }
            });
            tableBody = customHeaderBody + tableBody;
        }

        this.print(null, PrintService.getPrintHtmlStructure(tableBody, title, customStyles));
    }

    private static getPrintHtmlStructure(printHtmlBody, title = 'Untitled', customStyles?) {
        //there is a hacky solution in the body,
        //without style "border: 1px solid transparent OR heigth: 100vh" there is an blank page on the print view,
        //if the print view contains app-ccm-note tag.
        return `<html><head><title>${title}</title>${PrintService.getStyleTags()}<style>${customStyles}</style></head><body style="border: 1px solid transparent;"> ${printHtmlBody}</body></html>`;
    }

    private static getStyleTags(): string {
        const styleTags = Array.from(document.getElementsByTagName('style'));
        const linkTags = Array.from(document.getElementsByTagName('link')).map((link: HTMLStyleElement) => link.outerHTML.split('"').map(elementPart => {
            if (!elementPart.includes('css')) return elementPart;

            return `${location.protocol}//${location.host}/${elementPart}`;
        }).join('"'));

        return [...styleTags.map((element: Element) => element.outerHTML), ...linkTags].join('\r\n');
    }
}
