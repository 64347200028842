import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {SmartTableColSpec, SmartTableColTemplate, SmartTableComponent, SmartTableDefinition} from '../table/smart-table.component';
import {ReviewTypeKey, SlidePanelTypeKey} from '../../../../definitions/definitions';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {PrintService} from '../../../../@core/print.service';
import {FacilityChooserService} from '../../../../@core/facility-chooser.service';
import {DatePipe} from '@angular/common';
import {downloadTable} from '../../../../utils/file.utils';
import {SlidePanelOptions} from '../../../slide-panel/slide-panel';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';

export type TableSort = {
    col: SmartTableColSpec;
    desc: boolean;
};

@Directive()
export abstract class TableBaseComponent<T extends {selected?: boolean}> extends UnsubscribeComponent implements OnChanges {
    @Input() definition: SmartTableDefinition<T>;
    @Input() title: string;
    @Input() emptyText = 'No data to show.';
    @Input() colTemplates: SmartTableColTemplate[];
    @Input() insertTemplateCols: SmartTableColTemplate[];
    @Input() reviewType: ReviewTypeKey;
    @Input() showMoreButton = true;
    @Input() hasSorting = true;
    @Input() hasScrollContainer = true;
    @Input() downloadEnabled = false;
    @Input() selectable = false;
    @Input() sorting: TableSort = {
        col: null,
        desc: false,
    };
    @Input() showDetail = false;
    @Input() data: T[];
    @Input() extraRowTemplate: TemplateRef<any>;
    @Input() tableClasses = '';

    @Output() selectionChange = new EventEmitter<number[]>();

    selectedIds: number[];
    allRowsSelected: boolean;

    constructor(protected printService: PrintService,
                protected fc: FacilityChooserService,
                protected datePipe: DatePipe,
                protected slidePanelService: SlidePanelService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.processRowSpAction();
    }

    selectAll(event: boolean) {
        this.data.forEach(x => (x as any).selected = event);
        this.processSelected();
    }

    getSelectedId(item: T): number {
        return this.definition.selectedIdFn ? this.definition.selectedIdFn(item) : (item as any).id;
    }

    selectRow(selectedRow: {selected: boolean; item: T}) {
        selectedRow.item.selected = selectedRow.selected;
        this.processSelected();
    }

    processSelected(): number[] {
        this.selectedIds = this.data.filter(x => (x as any).selected).map(x => this.getSelectedId(x));
        this.selectionChange.emit(this.selectedIds);
        this.allRowsSelected = this.selectedIds.length === this.data.length;
        return this.selectedIds;
    }

    getDataArray() {
        return SmartTableComponent.getDataArray(this.data, this.definition, this.fc, this.datePipe);
    }

    onPrint() {
        this.printService.printTable(this.getDataArray());
    }

    onDownload(format: 'XLSX' | 'CSV') {
        const filename = this.title || this.definition.title || 'report';

        downloadTable(this.getDataArray(), filename, format);
    }

    getDataId(item: T): number {
        return this.definition.getDataId ? this.definition.getDataId(item) : (item as any).id;
    }

    onOpenSlidePanel({event, col, item}) {
        event.stopPropagation();
        this.openSlidePanel(
            col.spComponent,
            item,
            col.spComponentIdFn ? x => col.spComponentIdFn(x) : x => x.id,
            col.spComponentOptionsObj ? col.spComponentOptionsObj : null
        );
    }

    openSlidePanel(type: SlidePanelTypeKey, item, getId = (x): number => x.id, options?: SlidePanelOptions) {
        this.slidePanelService.addComponent(
            type,
            getId(item),
            this.data.map(data => ({
                id: getId(data),
                secondaryId: (data as any).id,
                active: item === data,
            })),
            true,
            options
        );
    }

    processRowSpAction() {
        if (this.definition && !this.definition.rowAction) {
            if (this.definition.rowSpComponent) {
                this.definition = {
                    ...this.definition,
                    rowAction: x => this.openSlidePanel(this.definition.rowSpComponent, x, d => this.getDataId(d)),
                };
            } else if (this.selectable) {
                this.definition = {...this.definition, rowAction: x => this.selectRow({selected: !(x as any).selected, item: x})};
            }
        }
    }
}
