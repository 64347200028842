import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SmartLinkDefinitions} from '../../../../definitions/definitions';
import {ReviewTypeKey} from '../../../../definitions/definitions';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {take} from 'rxjs/operators';
import {SmartLinkDefinition} from '../../../../definitions/definitions-base';

@Injectable()
export class PreviewService {
    constructor(private router: Router,
                private userAuthService: UserAuthService) {
    }

    openPreviewComponent(type: ReviewTypeKey, data) {
        this.userAuthService.user.pipe(take(1)).subscribe(user => {
            const def: SmartLinkDefinition = SmartLinkDefinitions[type];
            const hasPermission = def?.permissions ? def.permissions.some(x => user.permissions.includes(x)) : true;

            if (!hasPermission) return;
            if (!def?.getLink || !data || !data.id) return;

            this.router.navigate([def.getLink(data)], def.getFragment && {fragment: def.getFragment(data)});
        });
    }
}
