import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';
import {SmartTableColSpec, SmartTableColTemplate, SmartTableDefinition} from '../smart-table.component';
import {HoverActionsDirective} from '../../../../@portal-shared/hover-components/directives/hover-actions.directive';
import {ReviewTypeKey, SmartLinkDefinitions} from '../../../../../definitions/definitions';
import {PreviewService} from '../../../../@portal-shared/hover-components/services/preview.service';
import {UserAuthService} from '../../../../../@core/user-auth.service';
import {Router} from '@angular/router';
import {SlidePanelService} from '../../../../slide-panel/slide-panel.service';
import {PageThroughItem} from '../../../../slide-panel/slide-panel';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tr[smart-table-row]',
    templateUrl: './table-row.component.html',
})
export class TableRowComponent<T = any> extends HoverActionsDirective implements OnChanges {
    @Input() item: T;
    @Input() tableData?: T[];
    @Input() definition: SmartTableDefinition<T>;
    @Input() colTemplates: SmartTableColTemplate[];
    @Input() insertTemplateCols: SmartTableColTemplate[];
    @Input() selectable: boolean;
    @Input() shouldShowDetails: boolean;
    @Input() reviewType: ReviewTypeKey;
    @Input() last: boolean;
    @Input() sorting?: any;

    pageThrough = new Map<SmartTableColSpec<T>, PageThroughItem[]>();

    @Output() selectRow = new EventEmitter<{selected: boolean; item: T}>();
    @Output() openSlidePanel = new EventEmitter();

    @HostListener('click')
    onClick() {
        if (this.definition.rowAction) {
            this.definition.rowAction(this.item);
        } else if (this.reviewType) {
            this.preview.openPreviewComponent(this.reviewType, this.item);
        }
    }

    @HostBinding('class.cursor-pointer')
    get cursor() {
        return this.definition.rowAction || this.reviewType;
    }

    @HostBinding('class')
    get className() {
        const classes = [];
        if (this.definition.rowClass) classes.push(this.definition.rowClass(this.item));
        if (this.definition.rowAction || this.reviewType) classes.push('cursor-pointer');
        return classes.join(' ');
    }

    get actions() {
        return this.getRowHoverActions(this.definition, this.item);
    }

    constructor(el: ElementRef,
                vc: ViewContainerRef,
                renderer: Renderer2,
                authService: UserAuthService,
                zone: NgZone,
                slidePanelService: SlidePanelService,
                modalService: NgbModal,
                router: Router,
                private preview: PreviewService,
                cdref: ChangeDetectorRef) {
        super(el, vc, renderer, authService, zone, slidePanelService, modalService, router, cdref);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tableData || changes.sorting) {
            this.definition.cols.forEach(c => this.pageThrough.set(c, c.smartLinkType ?
                this.tableData?.map(x => ({
                    id: (this.getSmartLinkData(x, c) as any)?.id,
                    secondaryId: (x as any).id,
                })) :
                null
            ));
        }
    }

    getPageThrough(col: SmartTableColSpec<T>) {
        return this.pageThrough.get(col);
    }

    static getSmartLinkData<T>(item: T, col: SmartTableColSpec<T>) {
        return col.getSmartLinkData ? col.getSmartLinkData(item) : item;
    }

    getSmartLinkData(item: T, col: SmartTableColSpec<T>) {
        return TableRowComponent.getSmartLinkData(item, col);
    }

    static getValue<T>(item: T, col: SmartTableColSpec<T>) {
        if (col.getVal) return col.getVal(item);

        if (col.prop) return item[col.prop];

        if (col.smartLinkType) {
            const data = TableRowComponent.getSmartLinkData(item, col);
            return data && SmartLinkDefinitions[col.smartLinkType].getLabel(data);
        }
    }

    getValue(item: T, col: SmartTableColSpec<T>) {
        return TableRowComponent.getValue(item, col);
    }

    getPatient(item: T, col: SmartTableColSpec<T>) {
        if (!item) return;
        if (col.spComponentItemFn) return col.spComponentItemFn(item);
        if ((item as any).patient) return (item as any).patient;
        return item;
    }

    getRowHoverActions(definition: SmartTableDefinition<T>, item: T) {
        if (!definition.hoverActions) return null;

        return definition.hoverActions.map(action => ({
            ...action,
            callback: () => action.callback(item),
            shouldShowAction: action.shouldShowAction ? () => action.shouldShowAction(item) : null,
            active: action.active ? () => action.active(item) : null,
        }));
    }

    shouldShowUnit(col: SmartTableColSpec<T>, item: T) {
        if (!col.unit) return;
        const val = this.getValue(item, col);
        // We have to show the unit even if the value is zero
        return val || val === 0;
    }

    getTemplate(index: number): SmartTableColTemplate | SmartTableColSpec<T> {
        return this.colTemplates?.find(x => x.index === index) ||
            this.definition.cols[index].templateSpec ||
            this.definition.cols[index].template && this.definition.cols[index];
    }

    static getInsertTemplateCols(templateCols: SmartTableColTemplate[], defColLength = 0, index?: number): SmartTableColTemplate[] {
        const filterFn = typeof index === 'number' ? x => x.index == index : x => x.index >= defColLength || typeof x.index !== 'number';
        const itcs = templateCols && templateCols.filter(filterFn);
        return itcs && itcs.length ? itcs : null;
    }

    getInsertTemplateCols(index?: number) {
        return TableRowComponent.getInsertTemplateCols(this.insertTemplateCols, this.definition.cols.length, index);
    }

    onSelectRow(selected: boolean, item: T) {
        this.selectRow.emit({selected, item});
    }

    onOpenSlidePanel(event, col: SmartTableColSpec<T>, item: T) {
        this.openSlidePanel.emit({event, col, item});
    }
}
